import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';

import { OtpProps } from '../../../types';
import { userAuthService } from '../../../services';
import { FormContainer, Button } from '../../../components';
import { OtpForm } from '../../../components/forms/otp-form/otp-form';

export const OtpScreen: FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const phoneNumber = queryParams.get('PhoneNumber');
  const history = useHistory();
  const onSubmit = (formData: OtpProps) => {
    return userAuthService.confirmOtp(formData);
  };

  const onSuccess = () => {
    toast.success('Successfully Sent', { duration: 5000 });
    history.push('/login');
  };

  const onBack = () => {
    history.push('/forgot-password-otp');
  };

  const initialValues: OtpProps = {
    phoneNumber: phoneNumber ?? '',
    otp: '',
    confirmPassword: '',
    password: '',
  };

  return (
    <FormContainer>
      <Toaster position="top-center" />
      <div className="flex flex-col">
        <OtpForm initialValues={initialValues} submitForm={onSubmit} onSuccess={onSuccess} />
        <Button onClick={onBack} type="text" className="font-bold -ml-1">
          Back
        </Button>
      </div>
    </FormContainer>
  );
};
