import { isEmpty } from 'lodash';
import React, { FC, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { IconButton, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { Button, FormTextField } from '../../atoms';
import { OtpValidation } from '../../../validators';
import { ErrorObject, BaseFormProps, OtpProps } from '../../../types';

export const OtpForm: FC<BaseFormProps<OtpProps>> = ({ initialValues, submitForm, onSuccess }) => {
  const _handleFormSubmitError = (error: ErrorObject, actions: FormikHelpers<OtpProps>) => {
    actions.setSubmitting(false);
    const apiErrors = error.errors;
    if (!isEmpty(apiErrors)) {
      if (apiErrors.accountVerificationIncomplete) {
        actions.setFieldError('phoneNumber', apiErrors.accountVerificationIncomplete);
        actions.setFieldError('password', apiErrors.password);
        actions.setFieldError('confirmPassword', apiErrors.confirmPassword);
        actions.setFieldError('otp', apiErrors.Otp);
      } else actions.setFieldError('phoneNumber', apiErrors.phoneNumber);
    } else {
      actions.setFieldError('phoneNumber', error.message);
    }
  };

  const _handleSubmission = (formData: OtpProps, actions: FormikHelpers<OtpProps>) => {
    submitForm(formData)
      .then(() => {
        actions.setSubmitting(false);
        onSuccess();
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
      });
  };

  const [showPassword, setShowPassword] = useState(false);
  function _toggleShowPassword() {
    setShowPassword(!showPassword);
  }
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  function _toggleShowConfirmPassword() {
    setShowConfirmPassword(!showConfirmPassword);
  }

  const passwordButtonIcon = showPassword ? <Visibility /> : <VisibilityOff />;
  const confirmPasswordButtonIcon = showConfirmPassword ? <Visibility /> : <VisibilityOff />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={OtpValidation}
      validateOnBlur
      validateOnChange={false}
      onSubmit={_handleSubmission}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleReset,
      }) => (
        <form onReset={handleReset} onSubmit={handleSubmit} className="auth-form-body">
          <p className="auth-heading">Reset Password</p>
          <p className="auth-sub-heading">Enter the OTP and reset your Password.</p>
          <Typography className="self-start ml-14 text-xs">{`*******${values.phoneNumber.slice(
            -3,
          )}`}</Typography>
          <FormTextField
            variableName="otp"
            placeholder="OTP Number"
            className="w-4/5 self-center"
            values={values}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched}
            errors={errors}
            icon={<img src="assets/icons/lock-icon.png" alt="mail" />}
          />
          <div className="w-4/5">
            <Typography className="text-gray-500 font-light mb-1 text-xs">
              Password must be at least 6 characters long
            </Typography>
            <FormTextField
              variableName="password"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              icon={<img src="assets/icons/lock-icon.png" alt="password" />}
              endIcon={
                <IconButton size="small" onClick={_toggleShowPassword}>
                  {passwordButtonIcon}
                </IconButton>
              }
              required
              className="w-full self-center"
            />
          </div>
          <FormTextField
            variableName="confirmPassword"
            placeholder="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            values={values}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched}
            errors={errors}
            icon={<img src="assets/icons/lock-icon.png" alt="passwordConfirm" />}
            endIcon={
              <IconButton size="small" onClick={_toggleShowConfirmPassword}>
                {confirmPasswordButtonIcon}
              </IconButton>
            }
            required
            className="w-4/5 self-center"
          />
          <Button
            isLoading={isSubmitting}
            onClick={handleSubmit}
            className="auth-form-submit-button"
          >
            Reset Password
          </Button>
        </form>
      )}
    </Formik>
  );
};
