import { Formik, FormikHelpers } from 'formik';
import { isEmpty } from 'lodash';
import React, { FC } from 'react';

import { Button, FormTextField } from '../../atoms';
import { forgotPasswordValidation } from '../../../validators';
import { ErrorObject, BaseFormProps, ForgotPasswordProps } from '../../../types';

export const ForgotPasswordForm: FC<BaseFormProps<ForgotPasswordProps>> = ({
  initialValues,
  submitForm,
  onSuccess,
  onFailure,
}) => {
  const _handleFormSubmitError = (
    error: ErrorObject,
    actions: FormikHelpers<ForgotPasswordProps>,
  ) => {
    actions.setSubmitting(false);
    const apiErrors = error.errors;
    if (!isEmpty(apiErrors)) {
      if (apiErrors.accountVerificationIncomplete)
        actions.setFieldError('email', apiErrors.accountVerificationIncomplete);
      else actions.setFieldError('email', apiErrors.email);
    } else {
      actions.setFieldError('email', error.message);
    }
  };

  const _handleSubmission = (
    formData: ForgotPasswordProps,
    actions: FormikHelpers<ForgotPasswordProps>,
  ) => {
    submitForm(formData)
      .then(() => {
        actions.setSubmitting(false);
        onSuccess();
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
        onFailure();
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={forgotPasswordValidation}
      validateOnBlur
      validateOnChange={false}
      onSubmit={_handleSubmission}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleReset,
      }) => (
        <form onReset={handleReset} onSubmit={handleSubmit} className="auth-form-body">
          <p className="auth-heading">Email Address</p>
          <p className="auth-sub-heading mb-5">
            Enter the email address you used when you signed up and we&#39;ll send you instructions
            to reset your password.
          </p>
          <FormTextField
            variableName="email"
            placeholder="Email"
            className="w-4/5 self-center"
            values={values}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched}
            errors={errors}
            icon={<img src="assets/icons/mail-icon.png" alt="mail" />}
          />
          <Button
            isLoading={isSubmitting}
            onClick={handleSubmit}
            className="auth-form-submit-button"
          >
            Reset Password
          </Button>
        </form>
      )}
    </Formik>
  );
};
