import React from 'react';

export type HistoryNoteProps = {
  isLoading: boolean;
  value: string;
};

export const HistoryNote = React.forwardRef<HTMLTextAreaElement, HistoryNoteProps>(
  ({ isLoading, value }, ref) => (
    <div className="flex flex-col rounded-md h-64 shadow-md w-64">
      <div className="flex bg-secondary rounded-t-md h-16 p-4 flex-row justify-between items-center font-bold text-white">
        <p className="font-bold text-white font-sans text-base">Call History Notes</p>
      </div>
      <div className="flex overflow-auto text-xs">
        {!isLoading && <textarea ref={ref} className="w-full h-60 p-3" value={value} disabled />}
      </div>
    </div>
  ),
);
