import { AxiosResponse } from 'axios';
import { stringify } from 'qs';

import {
  BusinessEntity,
  FamilyMembersProps,
  FineDetailDto,
  GroupedBusinessFine,
  Guid,
  IndividualFilterData,
  IndividualFilterDataDto,
  PaginatedList,
  PaidOffender,
  PaidOffenderBusiness,
  PaymentPartnerFineFilterOptions,
  PortalFineStatus,
  ReportFilterProps,
  ServiceResult,
  UnpaidOffender,
  UnpaidOffenderBusiness,
} from '../../../types';
import finesUrls from './fines.urls';
import { ContentTypeEnum } from '../../../enums/content-type.enum';
import authNetworkService from '../auth-network/auth-network.service';
import adminFamilyMembersUrls from '../admin-family-members/admin-family-members.urls';

const get = async (
  filter: PaymentPartnerFineFilterOptions,
): Promise<PaginatedList<FineDetailDto[]>> => {
  const url = finesUrls.getBaseUrl();

  const response = await authNetworkService
    .get<ServiceResult<PaginatedList<FineDetailDto[]>>>(url, {
      params: {
        pageNum: filter.pageNum,
        noticeNumber: filter.noticeNumber,
        byNag: filter.byNag,
        pageEntries: filter.pageEntries,
        invoiceItemCreationReferenceId: filter.invoiceItemCreationReferenceId,
        status: filter.status,
      },
      paramsSerializer: (params) => stringify(params),
    })
    .catch(Promise.reject);
  return response.data.data;
};

const getCsv = async (filter: PaymentPartnerFineFilterOptions): Promise<AxiosResponse<Blob>> => {
  const url = `${finesUrls.getBaseUrl()}/Content`;

  return authNetworkService.get(url, {
    responseType: 'blob',
    timeout: 0,
    headers: { Accept: 'application/csv' },
    params: {
      noticeNumber: filter.noticeNumber,
      byNag: filter.byNag,
      invoiceItemCreationReferenceId: filter.invoiceItemCreationReferenceId,
      status: filter.status,
    },
    paramsSerializer: (params) => stringify(params),
  });
};

const fetchIndividualFilterData = (): Promise<IndividualFilterData[]> => {
  const url = finesUrls.getIndividualFilterData();

  return authNetworkService
    .get(url)
    .then(
      (apiResponse: AxiosResponse<ServiceResult<IndividualFilterDataDto[]>>) =>
        apiResponse.data.data,
    )
    .catch((error) => {
      return Promise.reject(error);
    });
};

const fetchBusinessFilterData = (): Promise<BusinessEntity[]> => {
  const url = finesUrls.getFleetFilterData();

  return authNetworkService
    .get(url)
    .then((apiResponse: AxiosResponse<ServiceResult<BusinessEntity[]>>) => apiResponse.data.data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

const fetchIndividualUnpaidFines = (
  ids: string[] = [],
  noticeNumber: string,
): Promise<UnpaidOffender[]> => {
  const serializedParams: Record<string, any> = {};

  if (ids.length > 0) {
    serializedParams.Filter = ids;
  }

  if (noticeNumber) {
    serializedParams.NoticeNumber = noticeNumber;
  }

  return authNetworkService
    .get(finesUrls.getIndividualUnpaidFinesUrl(), {
      params: serializedParams,
      paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
    })
    .then((apiResponse: AxiosResponse<ServiceResult<UnpaidOffender[]>>) => apiResponse.data.data)
    .catch((error) => Promise.reject(error));
};

const fetchBusinessUnpaidFines = (ids: string[] = []): Promise<UnpaidOffenderBusiness[]> => {
  let url = `${finesUrls.getFleetUnpaidFinesUrl()}?`;
  ids.forEach((id) => {
    url = url.concat(`Filter=${id}&`);
  });

  url = url.slice(0, -1);

  return authNetworkService
    .get(url)
    .then(
      (apiResponse: AxiosResponse<ServiceResult<UnpaidOffenderBusiness[]>>) =>
        apiResponse.data.data,
    )
    .catch((error) => {
      return Promise.reject(error);
    });
};

const getFleetFilteredFine = (
  entityId: string,
  offenderIdentifiers: string[] = [],
  fineStatus: PortalFineStatus = 'Unpaid',
): Promise<GroupedBusinessFine[]> => {
  const url = finesUrls.getFleetFilteredFineUrl(entityId);
  return authNetworkService
    .get(url, {
      params: {
        entityId,
        offenderIdentifiers,
        fineStatus,
      },
      paramsSerializer: (params) => stringify(params),
    })
    .then((apiResponse: AxiosResponse<ServiceResult<GroupedBusinessFine[]>>) => {
      return apiResponse.data.data;
    });
};

const downloadFleetFilteredFinesContent = (
  entityId: string,
  fleetName: string,
  offenderIdentifiers: string[] = [],
  fineStatus: PortalFineStatus = 'Unpaid',
  contentType = ContentTypeEnum.pdf,
): Promise<AxiosResponse<Blob>> => {
  const url = finesUrls.getFleetFilteredFineContentUrl(entityId);
  return authNetworkService.get(url, {
    responseType: 'blob',
    params: {
      entityId,
      fleetName,
      offenderIdentifiers,
      fineStatus,
      contentType,
    },
    paramsSerializer: (params) => stringify(params),
  });
};

const fetchIndividualPaidFines = (
  ids: string[] = [],
  noticeNumber: string,
): Promise<PaidOffender[]> => {
  const serializedParams: Record<string, any> = {};

  if (ids.length > 0) {
    serializedParams.Filter = ids;
  }

  if (noticeNumber) {
    serializedParams.NoticeNumber = noticeNumber;
  }

  return authNetworkService
    .get(finesUrls.getIndividualPaidFinesUrl(), {
      params: serializedParams,
      paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
    })
    .then((apiResponse: AxiosResponse<ServiceResult<PaidOffender[]>>) => apiResponse.data.data)
    .catch((error) => Promise.reject(error));
};

const fetchBusinessPaidFines = (ids: string[] = []): Promise<PaidOffenderBusiness[]> => {
  let url = `${finesUrls.getFleetPaidFinesUrl()}?`;
  ids.forEach((id) => {
    url = url.concat(`Filter=${id}&`);
  });

  url = url.slice(0, -1);
  return authNetworkService
    .get(url)
    .then(
      (apiResponse: AxiosResponse<ServiceResult<PaidOffenderBusiness[]>>) => apiResponse.data.data,
    )
    .catch((error) => {
      return Promise.reject(error);
    });
};

const fetchGroupedFines = (ids: string[] = []): Promise<UnpaidOffender[]> => {
  const url = `${finesUrls.getGroupedFinesUrl()}?`;
  const dto = {
    fineIds: ids,
  };

  return authNetworkService
    .post(url, dto)
    .then((apiResponse: AxiosResponse<ServiceResult<UnpaidOffender[]>>) => apiResponse.data.data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

const fetchGroupedBusinessFines = (
  entityId: Guid,
  ids: string[] = [],
): Promise<UnpaidOffender[]> => {
  let url = `${finesUrls.getFleetGroupedFinesUrl()}?`;
  url += `entityId=${entityId}`;
  const dto = {
    fineIds: ids,
  };

  return authNetworkService
    .post(url, dto)
    .then((apiResponse: AxiosResponse<ServiceResult<UnpaidOffender[]>>) => apiResponse.data.data)
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getFleetFinesTotalsReport = (
  filterProps?: ReportFilterProps,
): Promise<AxiosResponse<Blob>> => {
  const url = finesUrls.getBaseContentUrl();

  return authNetworkService.get(url, {
    responseType: 'blob',
    headers: { Accept: 'application/csv' },
    timeout: 0,
    params: {
      filters: filterProps,
      isFleet: true,
    },
    paramsSerializer: (params) => stringify(params, { allowDots: true }),
  });
};

export const getIndividualFinesTotalsReport = (
  filterProps?: ReportFilterProps,
): Promise<AxiosResponse<Blob>> => {
  const url = finesUrls.getBaseContentUrl();

  return authNetworkService.get(url, {
    responseType: 'blob',
    headers: { Accept: 'application/csv' },
    timeout: 0,
    params: {
      filters: filterProps,
      isIndividual: true,
      isFamilyMember: true,
    },
    paramsSerializer: (params) => stringify(params, { allowDots: true }),
  });
};

const addFamilyMember = (formData: FamilyMembersProps) => {
  const url = adminFamilyMembersUrls.addFamilyMemberUrl();

  return authNetworkService.post(url, formData);
};

export default {
  fetchIndividualFilterData,
  fetchIndividualUnpaidFines,
  fetchIndividualPaidFines,
  fetchGroupedFines,
  fetchBusinessUnpaidFines,
  fetchBusinessFilterData,
  fetchBusinessPaidFines,
  fetchGroupedBusinessFines,
  addFamilyMember,
  getFleetFilteredFine,
  downloadFleetFilteredFinesContent,
  getFleetFinesTotalsReport,
  getIndividualFinesTotalsReport,
  get,
  getCsv,
};
