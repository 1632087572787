import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';

import { FormContainer, ForgotPasswordForm, Button } from '../../../components';
import { ForgotPasswordProps } from '../../../types';
import { userAuthService } from '../../../services';

const ForgotPasswordScreen: FC = () => {
  const history = useHistory();

  const onSubmit = (formData: ForgotPasswordProps) => {
    return userAuthService.forgotPassword(formData);
  };

  const onSuccess = () => {
    toast.success('Successfully Sent', { duration: 5000 });
  };

  const onBack = () => {
    history.push('/login');
  };

  const onOTP = () => {
    history.push('/forgot-password-otp');
  };

  const initialValues: ForgotPasswordProps = {
    email: '',
  };

  return (
    <FormContainer>
      <Toaster position="top-center" />
      <div className="flex flex-col">
        <ForgotPasswordForm
          initialValues={initialValues}
          submitForm={onSubmit}
          onSuccess={onSuccess}
          onFailure={() => null}
        />
        <Button onClick={onOTP} type="text" className="-ml-1 w-max self-center">
          <span className="underline font-bold">Use OTP</span>
        </Button>
        <Button onClick={onBack} type="text" className="font-bold -ml-1">
          Back
        </Button>
      </div>
    </FormContainer>
  );
};

export default ForgotPasswordScreen;
