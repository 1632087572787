import { useHistory } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';

import { FormContainer, ForgotPasswordOtpForm, Button } from '../../../components';
import { ForgotPasswordOtpProps } from '../../../types';
import { userAuthService } from '../../../services';

export const ForgotPasswordOtpScreen: FC = () => {
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [shouldNavigate, setShouldNavigate] = useState(false);

  const onSubmit = async (formData: ForgotPasswordOtpProps) => {
    try {
      await userAuthService.forgotPasswordOtp(formData);
      const combinedNumber = formData.code + formData.cellphone;
      setPhoneNumber(combinedNumber);
      setShouldNavigate(true);
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (shouldNavigate && phoneNumber) {
      toast.success('Successfully Sent', { duration: 5000 });
      history.push(`/otp-confirm?PhoneNumber=${encodeURIComponent(phoneNumber)}`);
      setShouldNavigate(false);
    }
  }, [shouldNavigate, phoneNumber, history]);

  const onBack = () => {
    history.push('/login');
  };

  const initialValues: ForgotPasswordOtpProps = {
    code: '',
    cellphone: '',
  };

  return (
    <FormContainer>
      <Toaster position="top-center" />
      <div className="flex flex-col">
        <ForgotPasswordOtpForm initialValues={initialValues} submitForm={onSubmit} />
        <Button onClick={onBack} type="text" className="font-bold -ml-1">
          Back
        </Button>
      </div>
    </FormContainer>
  );
};
