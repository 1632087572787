import { Formik, FormikHelpers } from 'formik';
import React, { FC } from 'react';
import { isEmpty } from 'lodash';

import { Button, FormTextField } from '../../atoms';
import { forgotPasswordOtpValidation } from '../../../validators';
import { ErrorObject, BaseFormProps, ForgotPasswordOtpProps } from '../../../types';

export const ForgotPasswordOtpForm: FC<BaseFormProps<ForgotPasswordOtpProps>> = ({
  initialValues,
  submitForm,
}) => {
  const _handleFormSubmitError = (
    error: ErrorObject,
    actions: FormikHelpers<ForgotPasswordOtpProps>,
  ) => {
    actions.setSubmitting(false);
    const apiErrors = error.errors;
    if (!isEmpty(apiErrors)) {
      if (apiErrors.accountVerificationIncomplete)
        actions.setFieldError('PhoneNumber', apiErrors.accountVerificationIncomplete);
      else actions.setFieldError('PhoneNumber', apiErrors.phoneNumber);
    } else {
      actions.setFieldError('PhoneNumber', error.message);
    }
  };

  const _handleSubmission = (
    formData: ForgotPasswordOtpProps,
    actions: FormikHelpers<ForgotPasswordOtpProps>,
  ) => {
    submitForm(formData)
      .then(() => {
        actions.setSubmitting(false);
      })
      .catch((error: ErrorObject) => {
        _handleFormSubmitError(error, actions);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={forgotPasswordOtpValidation}
      validateOnBlur
      validateOnChange={false}
      onSubmit={_handleSubmission}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleReset,
      }) => (
        <form onReset={handleReset} onSubmit={handleSubmit} className="auth-form-body">
          <p className="auth-heading">Phone Number</p>
          <p className="auth-sub-heading">
            Enter the phone number you used when you signed up and we&#39;ll send you an OTP to
            reset your password.
          </p>
          <div className="w-4/5 self-center flex justify-between">
            <FormTextField
              variableName="code"
              placeholder="Code"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              icon={<img src="assets/icons/plus-icon.png" alt="code" />}
              required
              className="w-3/12"
            />
            <FormTextField
              variableName="cellphone"
              placeholder="Cellphone"
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              icon={<img src="assets/icons/phone-icon.png" alt="phone" />}
              required
              className="w-8/12"
            />
          </div>
          <Button
            isLoading={isSubmitting}
            onClick={handleSubmit}
            className="auth-form-submit-button"
          >
            Reset Password
          </Button>
        </form>
      )}
    </Formik>
  );
};
